<template>
    <div class="d-flex justify-content-center d-md-none my-4">
        <span class="section-title">WORK</span>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-center gap-4 align-items-center">
        <!-- Exp timeline -->
        <div>
            <div v-for="exp in experience" :key="exp.role" class="work-exp-wrapper">
                <div class="exp-date px-3 py-1 mb-2">{{ exp.dateJoined }}</div>
                <div class="exp-role mb-2">{{  exp.role }}</div>
                <div class="companyLogo">At <img src="@/assets/svg/mchp.svg" alt="company"></div> 
            </div>
        </div>
        

        <!-- Work svg -->
        <object :data="workSvg" type="image/svg+xml" class="work-exp-svg d-none d-md-block"></object>
        <!-- Key highlights -->
        <div class="key-highlights p-3">
            <div class="key-title">KEY HIGHLIGHTS AT MICROCHIP</div>
            <div class="mt-3">
                <ul>
                    <li>Design and refine UI/UX for upcoming projects.</li>
                    <li>Introduce new features and enhancements for Vue applications.</li>
                    <li>Drive new project development. Maintain and optimize multiple existing Vue applications.</li>
                    <li>Develop and integrate APIs using .NET and MongoDB.</li>
                    <li>Spearhead proof-of-concept initiatives for innovative solutions.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
// import {defineVueComponent} from 'vue';
import workExp from '@/assets/svg/work-exp.svg'

export default {
    name: "WorkSection",
    data() {
        return {
            workSvg: workExp,
            experience: [
                {
                    role: 'Software Engineer',
                    dateJoined: 'July 2023',
                    companyLogo: 'mchp.svg'
                },
                {
                    role: 'Software Engineer Intern',
                    dateJoined: 'January 2023',
                    companyLogo: 'mchp.svg',
                }
            ]
        }
    }
}

</script>