<template>
    <section class="professional-exp position-relative">
        <!-- navbar -->
        <div class="desktop-navbar d-sm-flex d-none mb-5 justify-content-center align-items-center">
            <div class="navList d-flex justify-content-center gap-5 p-2">
                <div v-for="item in navItems" :key="item.title"
                    class="d-flex gap-3 justify-content-start p-2 align-items-center"
                    :class="item.title == active ? 'active-item' : ''">
                    <i :class="item.icon"></i>
                    <router-link :to="{ name: item.title }">
                        <div class="cursor-pointer">{{ item.title }}</div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- Mobile Navbar -->

        <div class="hamburger-menu d-sm-none">
            <div class="menu-items mb-2" ref="menuItems">
                <div v-for="item in navItems" :key="item.title"
                    class="d-flex gap-3 justify-content-start p-2 align-items-center"
                    :class="item.title == active ? 'active-item' : ''">
                    <i :class="item.icon"></i>
                    <router-link :to="{ name: item.title }" @click="toggleNavMenu">
                        <div>{{ item.title }}</div>
                    </router-link>
                </div>
            </div>

            <div class="menu-icon" @click="toggleNavMenu">
                <i class="fa-solid fa-bars" ref="hamburgerIcon"></i>
            </div>
        </div>

        <div class="mt-5 d-grid">
            <router-view></router-view>
        </div>
       
    </section>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';

const route = useRoute();

const active = computed(() => route.name);
const menuItems = ref(null);
const hamburgerIcon = ref(null)
function toggleNavMenu() {
    menuItems.value.classList.toggle('show-menu-items');
    hamburgerIcon.value.classList.toggle('fa-bars');
    hamburgerIcon.value.classList.toggle('fa-xmark');
}

const navItems = [
    {
        title: 'Work',
        icon: 'fa-solid fa-laptop-code',
    },
    {
        title: 'Skills',
        icon: 'fa-solid fa-code',
    },
    // {
    //     title: 'Education',
    //     icon: 'fa-solid fa-graduation-cap',
    // },
    {
        title: 'Projects',
        icon: 'fa-solid fa-cubes-stacked',
    },
    {
        title: 'Achievements',
        icon: 'fa-solid fa-medal',
    }
];
</script>