<template>
    <div class="d-flex skill-wrapper justify-content-md-center align-items-center">
        <div class="skills-grid">
            <div class="frontend p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Vue')" alt="" width="50" height="50">
                    <div class="skill-name">Vue.js</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('React')" alt="" width="50" height="50">
                    <div class="skill-name">React.js</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Js')" alt="" width="50" height="50">
                    <div class="skill-name">Javascript</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Typescript')" alt="" width="50" height="50">
                    <div class="skill-name">Typescript</div> 
                </div>
            </div>
            <div class="backend p-3 d-flex flex-wrap justify-content-center align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('CSharp')" alt="" width="50" height="50">
                    <div class="skill-name">C# (C Sharp)</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Dotnet')" alt="" width="50" height="50">
                    <div class="skill-name">.NET Framework</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('MongoDB')" alt="" width="50" height="50">
                    <div class="skill-name">MongoDB</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('sqlserver')" alt="" width="50" height="50">
                    <div class="skill-name">MS SQL Server</div> 
                </div>
            </div>
            <div class="prog-lang p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Python')" alt="" width="50" height="50">
                    <div class="skill-name">Python</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Java')" alt="" width="50" height="50">
                    <div class="skill-name">Java</div> 
                </div>
            </div>
            <div class="skills-title p-3 d-flex justify-content-center align-items-center gap-4">
                <i class="fa-solid fa-code me-2"></i> <div class="title">Skills</div> 
            </div>
            <div class="html-css p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Html5')" alt="" width="50" height="50">
                    <div class="skill-name">HTML</div> 
                </div>
                <div class="skill"> 
                    <img :src="getLogoPath('Css3')" alt="" width="50" height="50">
                    <div class="skill-name">CSS</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Scss')" alt="" width="50" height="50">
                    <div class="skill-name">SASS</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Tailwind')" alt="" width="50" height="50">
                    <div class="skill-name">Tailwind</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Less')" alt="" width="50" height="50">
                    <div class="skill-name">Less CSS</div> 
                </div>
            </div>
            <div class="tools p-3 d-flex justify-content-around align-items-center gap-4">
                <div class="skill">
                    <img :src="getLogoPath('Github')" alt="" width="50" height="50">
                    <div class="skill-name">Github</div> 
                </div>
                <div class="skill">
                    <img :src="getLogoPath('Figma')" alt="" width="50" height="50">
                    <div class="skill-name">Figma</div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {defineVueComponent} from 'vue';

export default {
    name: "SkillSection",
    methods: {
        getLogoPath(logo) {
            return require(`@/assets/svg/skills/${logo}.svg`);
        }
    }
}

</script>